import React from 'react'
// import { withPrefix } from 'gatsby'
// import styles from "../styles/about.module.scss"
import Layout from '../components/layout'

const About = () => (
  <Layout>
    <div className="mx-6">
      <h1 className="mt-6 mb-4">Contact Me </h1>
      <p className="my-3 leading-normal text-lg w-full lg:w-4/5 text-color">
        Hi, I am current available for freelance projects on 
        <span className="text-highlight text-color"> python,</span>{" "}
        <span className="text-highlight text-color"> django,</span>{" "}
        <span className="text-highlight text-color"> nodejs,</span>{" "}
        <span className="text-highlight text-color"> react,</span>{" "}
        <span className="text-highlight text-color"> react native,</span>{" "}
        <span className="text-highlight text-color"> wordpress.</span>
      </p>
      <p className="my-3 leading-normal text-lg w-full lg:w-4/5 text-color">  Drop me a message with your project details. I will get back to you with
        very compativative rates for your project.{" "}</p>
      <hr className="hr" />
      <div className="text-4xl text-color">+44 77540 70075</div>
      <hr className="hr" />
      <div className="flex flex-row py-3">
        <div className="text-2xl w-32 text-color">E-mail: </div>
        <div className="text-2xl text-grey-dark">gaurav@jassal.me</div>
      </div>
      <div className="flex flex-row py-3">
        <div className="text-2xl w-32 text-color">Skype: </div>
        <div className="text-2xl text-grey-dark">gauravjassal</div>
      </div>
      <div className="flex flex-row py-3 pb-8">
        <div className="text-2xl w-32 text-color">Twitter: </div>
        <div className="text-2xl text-grey-dark">@igauravity</div>
      </div>
    </div>
  </Layout>
)

export default About
